var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex_layout"},_vm._l((_vm.mvs),function(item,index){return _c('div',{key:index,staticClass:"albums mv"},[(item.cover)?_c('router-link',{attrs:{"to":{ name: 'MV', params: { id: item.id, name: item.name } }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.cover + '?param=159y90'),expression:"item.cover + '?param=159y90'"}],key:item.cover + '?param=159y90',staticClass:"album_cover",attrs:{"alt":item.name,"title":item.name}})]):_vm._e(),(item.imgurl16v9)?_c('router-link',{attrs:{"to":{ name: 'MV', params: { id: item.id, name: item.name } }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.imgurl16v9 + '?param=159y90'),expression:"item.imgurl16v9 + '?param=159y90'"}],key:item.imgurl16v9 + '?param=159y90',staticClass:"album_cover",attrs:{"alt":item.name,"title":item.name}})]):_vm._e(),(item.artists)?_c('div',{staticClass:"info"},[_c('router-link',{attrs:{"to":{
                    name: 'MV',
                    params: { id: item.id, name: item.name },
                }}},[_vm._v(_vm._s(item.name))]),_c('span',_vm._l((item.artists),function(item,index){return _c('router-link',{key:index,attrs:{"to":{
                        name: 'Artist',
                        params: { id: item.id, name: item.name },
                    }}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),(item.artist)?_c('div',{staticClass:"info"},[_c('router-link',{attrs:{"to":{
                    name: 'MV',
                    params: { id: item.id, name: item.name },
                }}},[_vm._v(_vm._s(item.name))]),_c('router-link',{attrs:{"to":{
                    name: 'Artist',
                    params: { id: item.artist.id, name: item.artist.name },
                }}},[_vm._v(_vm._s(item.artistName))])],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }